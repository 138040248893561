import { DynamicPage, DynamicPageProps, getDynamicPageProps } from '~/templates/pages';
import { GetServerSidePropsContext } from 'next';
import { SafeNodeStorage } from '~/services/storage';
import DynamicLayout from '~/templates/pages/components/DynamicLayout/DynamicLayout';

export const getServerSideProps = (context: GetServerSidePropsContext) => {
    // Important for the require to be here for nextJS to work with FS/localStorage.
    const nodels = new (require('node-localstorage').LocalStorage)('./scratch');
    const storage = new SafeNodeStorage(nodels);

    return getDynamicPageProps(context, storage);
};

export default function SlugPage(props: DynamicPageProps) {
    return (
        <DynamicLayout pageProps={props}>
            <DynamicPage {...props} />
        </DynamicLayout>
    );
}
